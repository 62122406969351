export const updateWishlistInDatabase = async (wishlistItems, userId) => {
    const response = await fetch(process.env.REACT_APP_UPDATE_WISHLIST_URL, {
      method: "POST",
      body: JSON.stringify({ wishlistItems, userId }),
      headers: {
        "content-type": "application/json",
      },
    });
  
    return response;
  };
  