export const login = async (user) => {
    const response = await fetch(process.env.REACT_APP_LOGIN_URL, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "content-type": "application/json",
      },
    });
  
    return response.json();
  };
  