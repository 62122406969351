import cogoToast from 'cogo-toast';
import { updateCompareInDatabase } from '../../utils/updateCompare';
const { createSlice } = require('@reduxjs/toolkit');

const compareSlice = createSlice({
    name: "compare",
    initialState: {
        compareItems: []
    },
    reducers: {
        setCompareItems(state, action) {
            state.compareItems = action.payload;
        },
        clearCompare(state) {
            state.compareItems = [];
        },
        addToCompare(state, action) {
            const isInCompare = state.compareItems.findIndex(item => item.id === action.payload.id);
            if (isInCompare > -1) {
                cogoToast.info("El Artículo ya está en Comparación", { position: "top-right" });
            } else {
                state.compareItems.push(action.payload);
                cogoToast.success("Agregado a Comparación", { position: "top-right" });
                updateCompareInDatabase(state.compareItems, action.payload.userId);
            }
        },
        deleteFromCompare(state, action) {
            const updatedCompareItems = state.compareItems.filter(item => item.id !== action.payload.id);
            state.compareItems = updatedCompareItems;
            cogoToast.error("Eliminado de Comparación", { position: "top-right" });
            updateCompareInDatabase(updatedCompareItems, action.payload.userId);
        },
        deleteAllFromCompare(state, action) {
            const { userId } = action.payload;
            state.compareItems = [];
            updateCompareInDatabase(state.compareItems, userId);
        }
    },
});

export const { setCompareItems, clearCompare, addToCompare, deleteFromCompare, deleteAllFromCompare } = compareSlice.actions;
export default compareSlice.reducer;