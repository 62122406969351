import cogoToast from 'cogo-toast';
import { updateWishlistInDatabase } from '../../utils/updateWishList';
const { createSlice } = require('@reduxjs/toolkit');


const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: []
    },
    reducers: {
        setWishlistItems(state, action) {
            state.wishlistItems = action.payload;
        },
        clearWishlist(state) {
            state.wishlistItems = [];
        },
        addToWishlist(state, action) {
            const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
            if (isInWishlist > -1) {
                cogoToast.info("El Artículo ya es Favorito", { position: "top-right" });
            } else {
                state.wishlistItems.push(action.payload);
                cogoToast.success("Agregado a Favoritos", { position: "top-right" });
                updateWishlistInDatabase(state.wishlistItems, action.payload.userId);
            }
        },
        deleteFromWishlist(state, action) {
            const updatedWishlistItems = state.wishlistItems.filter(item => item.id !== action.payload.id);
            state.wishlistItems = updatedWishlistItems;
            cogoToast.error("Eliminado de Favoritos", { position: "top-right" });
            updateWishlistInDatabase(updatedWishlistItems, action.payload.userId);
        },
        deleteAllFromWishlist(state, action) {
            const { userId } = action.payload;
            state.wishlistItems = [];
            updateWishlistInDatabase(state.wishlistItems, userId);
        }
    },
});

export const { setWishlistItems, clearWishlist, addToWishlist, deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;