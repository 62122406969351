import { createContext, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCartItems, clearCart } from "../slices/cart-slice";
import { setWishlistItems, clearWishlist } from "../slices/wishlist-slice";
import { setCompareItems, clearCompare } from "../slices/compare-slice";
import { login } from "../../utils/login";
import { fetchUserState } from "../../utils/fetchUserState";

const UserContext = createContext();

const SESSION_EXPIRATION_TIME = 3600 * 1000; // 1 hora en milisegundos

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuth = localStorage.getItem('isAuthenticated');
    return savedAuth ? JSON.parse(savedAuth) : false;
  });
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);

  const checkSessionExpiration = useCallback(() => {
    const expirationTime = localStorage.getItem('sessionExpiration');
    if (expirationTime && Date.now() > Number(expirationTime)) {
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('user');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('sessionExpiration');
      dispatch(clearCart());
      dispatch(clearWishlist());
      dispatch(clearCompare());
    }
  }, [dispatch]);

  const renewSession = useCallback(() => {
    localStorage.setItem('sessionExpiration', Date.now() + SESSION_EXPIRATION_TIME);
  }, []);

  const setCurrentUser = useCallback(async (user) => {
    if (!user) {
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('user');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('sessionExpiration');
      dispatch(clearCart());
      dispatch(clearWishlist());
      dispatch(clearCompare());
      return Promise.resolve(null);
    }

    const json = await login(user);
    if (json.conectado) {
      setUser(json);
      setIsAuthenticated(true);
      localStorage.setItem('user', JSON.stringify(json));
      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      renewSession();

      // Fetch user state from the database
      const stateJson = await fetchUserState(json.id);

      // Match product_id with product details
      const matchedWishlistItems = stateJson.wishlistItems.map(item => {
        const product = products.find(p => p.id === item.product_id);
        return product ? { ...product } : null;
      }).filter(item => item !== null);

      const matchedCompareItems = stateJson.compareItems.map(item => {
        const product = products.find(p => p.id === item.product_id);
        return product ? { ...product } : null;
      }).filter(item => item !== null);

      const matchedCartItems = stateJson.cartItems.map(item => {
        const product = products.find(p => p.id === item.product_id);
        return product ? { ...product, quantity: item.quantity, selectedProductColor: item.selected_product_color, selectedProductSize: item.selected_product_size, cartItemId: item.cart_item_id } : null;
      }).filter(item => item !== null);

      dispatch(setCartItems(matchedCartItems));
      dispatch(setWishlistItems(matchedWishlistItems));
      dispatch(setCompareItems(matchedCompareItems));
    }
    return json;
  }, [dispatch, products, renewSession]);

  // Recupera el estado del usuario de localStorage en el inicio de la aplicación
  useEffect(() => {
    checkSessionExpiration();
    const savedUser = localStorage.getItem('user');
    const savedAuth = localStorage.getItem('isAuthenticated');
    if (savedUser && savedAuth) {
      setUser(JSON.parse(savedUser));
      setIsAuthenticated(JSON.parse(savedAuth));
      renewSession();
    }
  }, [checkSessionExpiration, renewSession]);

  const data = { user, isAuthenticated, setCurrentUser };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export { UserProvider };
export default UserContext;