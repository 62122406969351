export const updateCompareInDatabase = async (compareItems, userId) => {
    const response = await fetch(process.env.REACT_APP_UPDATE_COMPARE_URL, {
      method: "POST",
      body: JSON.stringify({ compareItems, userId }),
      headers: {
        "content-type": "application/json",
      },
    });
  
    return response;
  };