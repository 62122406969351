import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
import { updateCartInDatabase } from '../../utils/updateCart';
const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: []
    },
    reducers: {
        setCartItems(state, action) {
            state.cartItems = action.payload;
        },
        clearCart(state) {
            state.cartItems = [];
        },
        addToCart(state, action) {
            const { product, userId } = action.payload;
            if (!product) {
              console.error("Product is undefined");
              return;
            }
          
            if (!product.variation) {
              const cartItem = state.cartItems.find(item => item.id === product.id);
              if (!cartItem) {
                state.cartItems.push({
                  ...product,
                  quantity: product.quantity ? product.quantity : 1,
                  cartItemId: uuidv4()
                });
              } else {
                state.cartItems = state.cartItems.map(item => {
                  if (item.cartItemId === cartItem.cartItemId) {
                    return {
                      ...item,
                      quantity: Number(item.quantity) + Number(product.quantity) // Asegúrate de que se sumen los números
                    };
                  }
                  return item;
                });
              }
            } else {
              const cartItem = state.cartItems.find(
                item =>
                  item.id === product.id &&
                  product.selectedProductColor &&
                  product.selectedProductColor === item.selectedProductColor &&
                  product.selectedProductSize &&
                  product.selectedProductSize === item.selectedProductSize &&
                  (product.cartItemId ? product.cartItemId === item.cartItemId : true)
              );
              if (!cartItem) {
                state.cartItems.push({
                  ...product,
                  quantity: product.quantity ? product.quantity : 1,
                  cartItemId: uuidv4()
                });
              } else {
                state.cartItems = state.cartItems.map(item => {
                  if (item.cartItemId === cartItem.cartItemId) {
                    return {
                      ...item,
                      quantity: Number(item.quantity) + Number(product.quantity) // Asegúrate de que se sumen los números
                    };
                  }
                  return item;
                });
              }
            }
            cogoToast.success("Agregado a Mi Lista", { position: "top-right" })
            updateCartInDatabase(state.cartItems, userId);
        },
        deleteFromCart(state, action) {
            const { cartItemId, userId } = action.payload;
            state.cartItems = state.cartItems.filter(item => item.cartItemId !== cartItemId);
            cogoToast.error("Eliminado de Mi Lista", { position: "top-right" })
            updateCartInDatabase(state.cartItems, userId);
        },
        deleteAllFromCart(state, action) {
            const { userId } = action.payload;
            state.cartItems = [];
            updateCartInDatabase(state.cartItems, userId);
        },
        decreaseQuantity(state, action) {
            const { cartItemId, userId } = action.payload;
            const product = state.cartItems.find(item => item.cartItemId === cartItemId);
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.cartItemId !== cartItemId);
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.cartItemId === cartItemId
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
                );
            }
            updateCartInDatabase(state.cartItems, userId);
        }
    },
});

export const { setCartItems, clearCart, addToCart, deleteFromCart, deleteAllFromCart, decreaseQuantity } = cartSlice.actions;
export default cartSlice.reducer;
