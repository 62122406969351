import React, { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute"; // Ajusta el path según tu estructura de proyecto

// home pages
const Index = lazy(() => import("./pages/home/Index"));

// shop pages
const Coleccion = lazy(() => import("./pages/shop/Coleccion"));

// product pages
const Articulos = lazy(() => import("./pages/shop-product/Articulos"));

// blog pages
const FAQs = lazy(() => import("./pages/blog/FAQs"));
const FAQsDetails = lazy(() => import("./pages/blog/FAQsDetails"));

// other pages
const Contacto = lazy(() => import("./pages/other/Contacto"));
const PuntosDeVenta = lazy(() => import("./pages/other/PuntosDeVenta"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Login = lazy(() => import("./pages/other/Login"));
const Register = lazy(() => import("./pages/other/Register"));
const RecoverPassword = lazy(() => import("./pages/other/RecoverPassword"));
const Lista = lazy(() => import("./pages/other/Lista"));
const Favoritos = lazy(() => import("./pages/other/Favoritos"));
const Comparar = lazy(() => import("./pages/other/Comparar"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Search = lazy(() => import("./pages/other/Search"));


const App = () => {
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="belen-intima-preloader-wrapper">
                <div className="belen-intima-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route path={process.env.PUBLIC_URL + "/"} element={<Index />} />
              <Route path={process.env.PUBLIC_URL + "/login"} element={<Login />} />
              <Route path={process.env.PUBLIC_URL + "/register"} element={<Register />} />
              <Route path={process.env.PUBLIC_URL + "/recover-password"} element={<RecoverPassword />} />
              
              {/* Páginas sin protección */}
              <Route path={process.env.PUBLIC_URL + "/coleccion"} element={<Coleccion />} />
              <Route path={process.env.PUBLIC_URL + "/articulos/:id"} element={<Articulos />} />
              <Route path={process.env.PUBLIC_URL + "/faqs"} element={<FAQs />} />
              <Route path={process.env.PUBLIC_URL + "/faqs-details/:id"} element={<FAQsDetails />} />
              <Route path={process.env.PUBLIC_URL + "/contacto"} element={<Contacto />} />
              <Route path={process.env.PUBLIC_URL + "/puntos-de-venta"} element={<PuntosDeVenta />} />
              <Route path={process.env.PUBLIC_URL + "/search"} element={<Search />} />
              
              {/* Páginas protegidas */}
              <Route element={<ProtectedRoute />}>
                <Route path={process.env.PUBLIC_URL + "/my-account"} element={<MyAccount />} />
                <Route path={process.env.PUBLIC_URL + "/lista"} element={<Lista />} />
                <Route path={process.env.PUBLIC_URL + "/favoritos"} element={<Favoritos />} />
                <Route path={process.env.PUBLIC_URL + "/comparar"} element={<Comparar />} />
                <Route path={process.env.PUBLIC_URL + "/checkout"} element={<Checkout />} />
              </Route>
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;

